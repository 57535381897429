import { NavRouter } from "./Pages/Routerpage/NavRouter";
import { HelmetProvider } from "react-helmet-async";
import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import SEO from "./Pages/helper/SEO";
import ApiRequest from "./Pages/helper/ApiRequest";
import { ApiEndpoints } from "./Pages/helper/ApiEndpoints";
// import ChatWidget from "./Pages/chatBoat/ChatWidget";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DemoButton from "./Pages/demoBtn/DemoButton";

// const loadChatbotScripts = () => {
//   // Load the script dynamically
//   const script = document.createElement("script");
//   script.src = "//in.fw-cdn.com/31437866/703378.js";
//   script.setAttribute("chat", "true");
//   script.setAttribute("widgetId", "29ce8892-b5f9-4501-b7fa-9dfd898527c5");
//   document.body.appendChild(script);

//   // Configure the chatbot
//   window.fcWidgetMessengerConfig = { tags: ["b2b sales"] };
//   script.onload = () => {
//     setTimeout(() => {
//       if (window.fcWidget) window.fcWidget.open();
//     }, 10000); // 10 seconds delay
//   };
// };

export default function MainApp() {
  const [seoData, setSeoData] = useState(null);
  const seoListEndpoint = ApiEndpoints.seo.seoList;
  const location = useLocation();

  // loading chatbot script
  // useEffect(() => {
  //   loadChatbotScripts();

  //   return () => {
  //     // Cleanup: Remove the script if needed
  //     const existingScript = document.querySelector(
  //       'script[src="//in.fw-cdn.com/31437866/703378.js"]'
  //     );
  //     if (existingScript) existingScript.remove();
  //   };
  // }, []);

  // Storing referrer or current URL in sessionStorage
  useEffect(() => {
    const sessionCheck = sessionStorage.getItem("sourceUrlSession");
    if (!sessionCheck) {
      const referrer = document.referrer || window.location.href;
      sessionStorage.setItem("sourceUrlSession", referrer);
    }
  }, []);

  // Fetching SEO data based on current URL
  useEffect(() => {
    const dataFetch = async () => {
      try {
        const response = await ApiRequest("GET", seoListEndpoint);
        const result = response.seos ;
        // console.log(result);
        const currentPath = window.location.pathname;
         // Use pathname instead of full URL
        // console.log(currentPath , "currentPath")
        // const seoForCurrentPage = result.find((seo) => seo.url === currentPath);
        const seoForCurrentPage = result.find(
          (seo) => new URL(seo.url).pathname === currentPath
        );
        // console.log(seoForCurrentPage, ">>>>>")
        setSeoData(seoForCurrentPage ); 
        // console.log("seoData>>>>>", seoData )// Handle the case when no SEO data is found
      } catch (error) {
        console.error("Error fetching SEO data:", error);
      }
    };
    dataFetch();

  }, [location.pathname]); // Use location.pathname for URL matching
   

  const NormalizeTrailingSlash = () => {
    const location = useLocation();
  
    useEffect(() => {
      if (location.pathname !== '/' && location.pathname.endsWith('/')) {
        window.history.replaceState(
          null,
          '',
          location.pathname.slice(0, -1) + location.search + location.hash
        );
      }
    }, [location]);
  
    return null;
  };
  return (
    <>
      <HelmetProvider>
        <SEO seo={seoData} />
        <DemoButton/>
        <NormalizeTrailingSlash />
          <NavRouter />
          <ToastContainer/>
      </HelmetProvider>
    </>
  )
}
