import React from "react";
import "../../custom.css"
import { useDispatch } from "react-redux";
import { openPopup } from "../../store/popupSlice"
import QueryPopup from "../Query_popup/QueryPopup";
import { Helmet } from "react-helmet";

const DemoButton = () => {
  const dispatch = useDispatch();

  return (
    <>
      <Helmet>
        <link rel="preload" href="/assets/home/book-demo-float.webp" as="image" type="image/webp" />
      </Helmet>
      <div onClick={() => dispatch(openPopup())} className="book-demo-floatbtn">
        <img loading="lazy" width={321} height={197} src="/assets/home/book-demo-float.webp" alt="button" className="img-responsive" />
        <QueryPopup />
      </div>
    </>
  );
};

export default DemoButton;
